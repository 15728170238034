import { useEffect } from 'react';

import { VegaBox, VegaFont, VegaLoadingIndicator } from '@heartlandone/vega-react';
import isEmpty from 'lodash/isEmpty';

import HeartlandLogo from 'components/layout/logo';
import { TransitionTypes } from 'types/app';

import styles from './loginSplash.module.css';

interface Props {
  transition?: TransitionTypes;
  animationFinish?: () => void;
  message?: string;
}

const LoginSplash: React.FC<Props> = ({ transition, animationFinish, message }) => {
  useEffect(() => {
    if (transition && animationFinish) {
      const timer = setTimeout(animationFinish, 200);

      return () => clearTimeout(timer);
    }
  }, [animationFinish, transition]);

  return (
    <VegaBox
      className={
        transition && animationFinish ? styles.loginContainerFade + ' ' + styles.loginContainer : styles.loginContainer
      }
    >
      <VegaBox>
        <HeartlandLogo height={40} width={135} />
        <VegaFont color="text-secondary">My Account</VegaFont>
      </VegaBox>
      <VegaBox padding={'size-16'} className={styles.loadingBar}>
        <VegaLoadingIndicator
          mode={'indeterminate'}
          shape="bar"
          data-testid="progress"
          hint={isEmpty(message) ? ' ' : message}
        ></VegaLoadingIndicator>
      </VegaBox>
    </VegaBox>
  );
};

export default LoginSplash;
