import { ProgressLevel } from 'components/progressTrackerBar/progressTrackerBar.types';

const progressSegmentId = 'progressTrackerSegment';

const progressFillColor: Record<ProgressLevel, string> = {
  [ProgressLevel.Completed]: '#01801F',
  [ProgressLevel.InProgress]: '#ED7A23',
  [ProgressLevel.NoProgress]: '#D9D9D9',
};

const progressTrackerBarConstants = {
  progressSegmentId,
  progressFillColor,
};

export default progressTrackerBarConstants;
