import { useCallback, useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'next-i18next';

import { Page } from 'types/pages';
import { UserFlags } from 'types/user';

import { ConfigPage } from './usePages.types';
import { getAllApplicationPages } from './usePages.util';

// this is largely just a constant that is only a hook for translation purposes
// it doesn't need testing
/* istanbul ignore next */
function usePages() {
  const { t } = useTranslation('common');

  const flags = useFlags<UserFlags>();

  const applicationPages: Page[] = useMemo(() => getAllApplicationPages(t, flags), [flags, t]);

  const setPageTitle = useCallback(
    (route: string) => {
      let pageTitle = route;
      for (const page of applicationPages) {
        if (page.path === route) {
          pageTitle = page.title;
        } else {
          if (page.children) {
            for (const child of page.children) {
              if (child.path === route) {
                pageTitle = child.title;
              }
            }
          }
        }
      }

      return `MyAccount | ${pageTitle}`;
    },
    [applicationPages],
  );

  const configurationPages = useMemo(() => {
    const allPages = getAllApplicationPages(t);
    const configPages: ConfigPage[] = [];

    for (const page of allPages) {
      if (page.isConfigurable) {
        const configPage: ConfigPage = { ...page };
        configPages.push(configPage);
        if (configPage.children) {
          configPage.childPaths = configPage.children.map((childPage) => childPage.path);

          for (const childPage of configPage.children) {
            if (childPage.isConfigurable) configPages.push(childPage);
          }
        }
      }
    }

    return configPages;
  }, [t]);

  return {
    configurationPages,
    applicationPages,
    setPageTitle,
  };
}

export default usePages;
