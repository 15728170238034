import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.3.46612" />
      <meta name="build-date" content="2025-01-21T16:57:12Z" />
    </Head>
  );
};

export default Versioning;
