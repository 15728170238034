import { BackgroundTypes } from './app';

export enum RedirectType {
  NewTab = 'newTab',
  CurrentWindow = 'currentWindow',
}

export type Page = {
  path: string;
  securedExternalRoute?: string;
  title: string;
  parent?: string;
  parentTitle?: string;
  icon?: string;
  background: BackgroundTypes;
  section: string;
  isVisible: boolean;
  isConfigurable?: boolean;
  isLocked?: boolean;
  children?: Array<Page>;
  redirectType?: RedirectType;
};

export enum PageSections {
  QuickLinks = 'pageSections.quickLinks',
  Account = 'pageSections.account',
  UnGrouped = '',
  PowerTools = 'pageSections.powerTools',
}
export const routes = {
  accountSettings: '/accountSettings',
  ach: '/ACH',
  batchDetails: '/reports/batchesAndTransactions/[batchNumber]',
  batchesAndTransactions: '/reports/batchesAndTransactions',
  benefitsAdministration: '/benefitsAdministration',
  billPay: '/billPay',
  checkout: '/buyEquipment/checkout',
  disputes: '/disputes',
  equipment: '/buyEquipment',
  equipmentDetails: '/buyEquipment/[name]',
  funding: '/reports/funding',
  hiringAndOnboarding: '/hiringAndOnboarding',
  home: '/',
  impersonateUser: '/impersonateUser',
  inPerson: '/inPerson',
  loans: '/loans',
  paymentsPlusGroup: '#paymentsPlus',
  paymentsPlus: '/paymentsPlus',
  payrollPlusGroup: '#payrollPlus',
  payrollPlus: '/payroll',
  permissionOverride: '/permissionOverride',
  pointOfSaleGroup: '#pos',
  pointOfSale: '/pos',
  reports: '/reports',
  myEquipment: '/myEquipment',
  restaurant: '/restaurant',
  retail: '/retail',
  sgaSimulator: '/sgaSimulator',
  statementReport: '/reports/statement',
  store: '/store',
  support: '/support',
  timeAndAttendance: '/timeAndAttendance',
  userManagement: '/users',
  configuration: '/configuration',
};

export const externalRoutes = {
  billPayMerchantView: 'Bill Pay',
  infoCentral: 'Info Central',
  infoCentralBatchesReports: 'Info Central Batches Reports',
  infoCentralFundingReports: 'Info Central Funding Reports',
  infoCentralStatementsReports: 'Info Central Statements Reports',
  infoCentralTransactionReports: 'Info Central Transactions Reports',
  paymentsDashboard: 'Payments Dashboard',
  payrollBenefitsAdministration: 'Benefits Administration',
  payrollHiringAndBoarding: 'Hiring & Onboarding',
  payrollPlus: 'Payroll+',
  payrollTimeAndAttendance: 'Time & Attendance',
};

export const pageTitles = {
  accountSettings: 'pageTitles.accountSettings',
  achReporting: 'pageTitles.achReporting',
  batchesAndTransactions: 'pageTitles.batchesAndTransactions',
  billPay: 'pageTitles.billPay',
  capital: 'pageTitles.capital',
  checkout: 'pageTitles.checkout',
  disputes: 'pageTitles.disputes',
  equipment: 'pageTitles.equipment',
  funding: 'pageTitles.funding',
  helpAndSupport: 'pageTitles.helpAndSupport',
  home: 'pageTitles.home',
  impersonateUser: 'pageTitles.impersonateUser',
  inPerson: 'pageTitles.inPerson',
  integrations: 'pageTitles.integrations',
  support: 'pageTitles.support',
  paymentManagerPlus: 'pageTitles.paymentManagerPlus',
  paymentsPlus: 'pageTitles.paymentsPlus',
  payroll: 'pageTitles.payroll',
  payrollBenefitsAdministration: 'pageTitles.payrollBenefitsAdministration',
  payrollHiringAndBoarding: 'pageTitles.payrollHiringAndBoarding',
  payrollPlus: 'pageTitles.payrollPlus',
  payrollTimeAndAttendance: 'pageTitles.payrollTimeAndAttendance',
  permissionOverride: 'pageTitles.permissionOverride',
  pointOfSale: 'pageTitles.pointOfSale',
  reports: 'pageTitles.reports',
  myEquipment: 'pageTitles.myEquipment',
  restaurant: 'pageTitles.restaurant',
  retail: 'pageTitles.retail',
  sgaSimulator: 'pageTitles.sgaSimulator',
  statementReport: 'pageTitles.statementReport',
  userManagement: 'pageTitles.userManagement',
  configuration: 'pageTitles.configuration',
};

export const unauthorizedRoute = '/unauthorized';
export const notFoundRoute = '/404';
