import React, { useContext, useMemo } from 'react';

import { VegaBox, VegaButtonLink, VegaCard, VegaFont, VegaGrid, VegaIcon } from '@heartlandone/vega-react';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { CanAccessBatchesAndTransactions } from 'components/user/auth/permissions';
import { UserContext } from 'components/user/auth/userContext';
import UserNameIcon from 'components/user/userNameIcon';
import useOpenBatchAmountData from 'hooks/reports/useOpenBatchAmountData';
import { AppContext } from 'types/app';
import { routes } from 'types/pages';
import { UNKNOWN } from 'types/user';
import { numberWithCommas } from 'utility/helper/helperFunctions';
import { DeviceIsMobile } from 'utility/showOnDevice';

import styles from './index.module.css';

const WelcomeCard: React.FC = () => {
  const user = useContext(UserContext);
  const isMobile = DeviceIsMobile();
  const appContext = useContext(AppContext);
  const router = useRouter();
  const { t } = useTranslation('welcome-card');

  const { data, dataUpdatedAt } = useOpenBatchAmountData(CanAccessBatchesAndTransactions(user));

  const nameInitials = useMemo(() => {
    if (user.firstName === UNKNOWN || user.lastName === UNKNOWN) return '';

    let initials = user.firstName.charAt(0) + user.lastName.charAt(0);

    try {
      if (isEmpty(initials)) {
        initials = user.name.charAt(0) + user.name.split(' ').pop()?.charAt(0);
      }
    } catch (error) {
      initials = '??';
      console.error(error);
    }
    return initials;
  }, [user.name, user.firstName, user.lastName]);

  return (
    <VegaCard padding={'size-24'} className={styles.welcomeCard}>
      <VegaGrid column={2} style={{ gridTemplateColumns: `${isMobile ? '60' : '76'}px 1fr` }}>
        <div style={{ gridRow: 'span 2' }}>
          {isEmpty(nameInitials) ? (
            <VegaBox backgroundColor="bg-accent5-primary" className={styles.initials}>
              <VegaIcon data-testid="icon-user" icon="fa fa-user" size="size-40" />
            </VegaBox>
          ) : (
            <UserNameIcon theme={appContext.theme} size="WelcomeCard" />
          )}
        </div>
        <VegaFont color="text-primary" variant={'font-h5'} className={styles.welcomeMessage}>
          {t(isEmpty(nameInitials) ? 'welcomeWithoutName' : 'welcome', {
            firstName: user.firstName === UNKNOWN ? '' : user.firstName,
            lastName: user.lastName === UNKNOWN ? '' : user.lastName,
          })}
        </VegaFont>
        <VegaFont
          variant="font-field-label"
          color="text-secondary"
          style={{ gridColumn: isMobile ? 'span 2' : 'span 1', marginTop: isMobile ? '16px' : '0' }}
        >
          {!user.isVerified && <>{t('subtitle-unverified')}</>}
          {user.isVerified && (
            <>
              <span>{t('subtitle1')}&nbsp;</span>
              {user.userHasPaymentsAccounts && CanAccessBatchesAndTransactions(user) && (
                <>
                  <span>
                    {t('subtitle-payments', {
                      time: format(dataUpdatedAt, 'h:mmaa'),
                      date: format(dataUpdatedAt, 'MMM d, yyyy'),
                    })}
                  </span>
                  <VegaButtonLink
                    data-testid="button-link-batches-and-transactions"
                    onVegaClick={() => {
                      router.push(routes.batchesAndTransactions);
                    }}
                    label={`$${numberWithCommas(data?.totalAmount ?? 0)}`}
                  />
                </>
              )}
            </>
          )}
        </VegaFont>
      </VegaGrid>
    </VegaCard>
  );
};

export default WelcomeCard;
