const progressTrackerSegmentConstants = {
  emphasizedWidth: '326',
  normalWidth: '316',
  normalViewHeight: '24',
  emphasizedViewHeight: '34',
  normalSvgHeight: '30',
  emphasizedSvgHeight: '35',
  svgFilterDropShadow: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10))',
};

export default progressTrackerSegmentConstants;
