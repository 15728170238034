import { VegaBox, VegaFlex, VegaTooltip } from '@heartlandone/vega-react';

import ProgressTrackerSegment, { SegmentType } from 'components/progressTrackerBar/progressTrackerSegment';

import progressTrackerBarConstants from './progressTrackerBar.constants';
import { ProgressLevelDisplay } from './progressTrackerBar.types';

import styles from './progressTrackerBar.module.css';

export interface ProgressTrackerBarProps {
  states: ProgressLevelDisplay[];
}

const { progressSegmentId, progressFillColor } = progressTrackerBarConstants;

function ProgressTrackerBar({ states }: ProgressTrackerBarProps) {
  const firstState = states[0];
  const lastState = states[states.length - 1];

  return (
    <VegaFlex direction="row" gap="size-4" flexWrap="nowrap" style={{ height: '1.5rem' }} alignItems="center">
      <VegaBox className={styles.progressSegment}>
        <VegaTooltip text={firstState.displayName}>
          <ProgressTrackerSegment
            segmentType={SegmentType.Start}
            isEmphasized={firstState.isEmphasized}
            fill={progressFillColor[firstState.progressLevel]}
            id={progressSegmentId}
          />
        </VegaTooltip>
      </VegaBox>
      {states.slice(1, -1).map((state) => (
        <VegaBox key={state.displayName} className={styles.progressSegment}>
          <VegaTooltip text={state.displayName}>
            <ProgressTrackerSegment
              segmentType={SegmentType.Middle}
              isEmphasized={state.isEmphasized}
              fill={progressFillColor[state.progressLevel]}
              id={progressSegmentId}
            />
          </VegaTooltip>
        </VegaBox>
      ))}
      <VegaBox className={styles.progressSegment}>
        <VegaTooltip text={lastState.displayName}>
          <ProgressTrackerSegment
            segmentType={SegmentType.End}
            isEmphasized={lastState.isEmphasized}
            fill={progressFillColor[lastState.progressLevel]}
            id={progressSegmentId}
          />
        </VegaTooltip>
      </VegaBox>
    </VegaFlex>
  );
}

export default ProgressTrackerBar;
