export enum ProgressLevel {
  NoProgress = 'NoProgress',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export type ProgressLevelDisplay = {
  displayName: string;
  progressLevel: ProgressLevel;
  isEmphasized?: boolean;
};
