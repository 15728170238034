import progressTrackerSegmentConstants from './progressTrackerSegment.constants';

export enum SegmentType {
  Start,
  Middle,
  End,
}

export interface ProgressTrackerSegmentProps {
  segmentType: SegmentType;
  isEmphasized: boolean | undefined;
  fill: string;
  id?: string;
}

const {
  svgFilterDropShadow,
  normalWidth,
  emphasizedWidth,
  normalViewHeight,
  emphasizedViewHeight,
  normalSvgHeight,
  emphasizedSvgHeight,
} = progressTrackerSegmentConstants;

function ProgressTrackerSegment({ segmentType, isEmphasized, fill, id }: ProgressTrackerSegmentProps) {
  let segmentContent: JSX.Element;

  switch (segmentType) {
    case SegmentType.Start: {
      segmentContent = isEmphasized ? (
        <>
          <g filter="url(#filter0_dd_3736_1228)">
            <path d="M5 13C5 6.37258 10.3726 1 17 1H319.054C320.692 1 321.636 2.86134 320.667 4.18244L306.006 24.1824C305.629 24.6964 305.03 25 304.393 25H17C10.3726 25 5 19.6274 5 13Z" />
          </g>
          <defs>
            <filter
              id="filter0_dd_3736_1228"
              x="0"
              y="0"
              width="100%"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_3736_1228" />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3736_1228" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_3736_1228" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="3" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_3736_1228" result="effect2_dropShadow_3736_1228" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3736_1228" result="shape" />
            </filter>
          </defs>
        </>
      ) : (
        <path d="M0 12C0 5.37258 5.37258 0 12 0H314.054C315.692 0 316.636 1.86134 315.667 3.18244L301.006 23.1824C300.629 23.6964 300.03 24 299.393 24H12C5.3726 24 0 18.6274 0 12Z" />
      );
      break;
    }
    case SegmentType.Middle: {
      segmentContent = isEmphasized ? (
        <>
          <g filter="url(#filter0_dd_3763_1506)">
            <path d="M20.994 1.81756C21.3708 1.30364 21.9699 1 22.6071 1H318.054C319.692 1 320.636 2.86134 319.667 4.18244L305.006 24.1824C304.629 24.6964 304.03 25 303.393 25H7.94594C6.3079 25 5.36447 23.1387 6.33291 21.8176L20.994 1.81756Z" />
          </g>
          <defs>
            <filter
              id="filter0_dd_3763_1506"
              x="0.942383"
              y="0"
              width="100%"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_3763_1506" />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3763_1506" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_3763_1506" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="3" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_3763_1506" result="effect2_dropShadow_3763_1506" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3763_1506" result="shape" />
            </filter>
          </defs>
        </>
      ) : (
        <path d="M15.994 0.817562C16.3708 0.303641 16.9699 0 17.6071 0H313.054C314.692 0 315.636 1.86134 314.667 3.18244L300.006 23.1824C299.629 23.6964 299.03 24 298.393 24H2.94594C1.3079 24 0.36447 22.1387 1.33291 20.8176L15.994 0.817562Z" />
      );
      break;
    }
    case SegmentType.End: {
      segmentContent = isEmphasized ? (
        <>
          <g filter="url(#filter0_dd_3763_1187)">
            <path d="M20.994 1.81756C21.3708 1.30364 21.9699 1 22.6071 1H310C316.627 1 322 6.37258 322 13C322 19.6274 316.627 25 310 25H7.94592C6.30788 25 5.36447 23.1387 6.33291 21.8176L20.994 1.81756Z" />
          </g>
          <defs>
            <filter
              id="filter0_dd_3763_1187"
              x="0.942383"
              y="0"
              width="100%"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_3763_1187" />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3763_1187" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_3763_1187" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="3" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_3763_1187" result="effect2_dropShadow_3763_1187" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3763_1187" result="shape" />
            </filter>
          </defs>
        </>
      ) : (
        <path d="M15.994 0.817562C16.3708 0.303641 16.9699 0 17.6071 0H305C311.627 0 317 5.37258 317 12C317 18.6274 311.627 24 305 24H2.94592C1.30788 24 0.36447 22.1387 1.33291 20.8176L15.994 0.817562Z" />
      );
      break;
    }
    default: {
      segmentContent = <></>;
      break;
    }
  }

  const viewHeight = isEmphasized ? emphasizedViewHeight : normalViewHeight;
  const svgHeight = isEmphasized ? emphasizedSvgHeight : normalSvgHeight;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height={svgHeight}
      viewBox={`0 0 ${isEmphasized ? emphasizedWidth : normalWidth} ${viewHeight}`}
      filter={isEmphasized ? svgFilterDropShadow : ''}
      fill={fill}
      data-testid={id}
    >
      {segmentContent}
    </svg>
  );
}

export default ProgressTrackerSegment;
