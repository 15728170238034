import { ApplicationStatus } from 'components/dashboard/applicationTracker/applicationTracker.types';
import { CustomerAccountSettings } from 'types/accountSettings';
import {
  AllPermissionsResult,
  CustomerId,
  MDMCustomerAddress,
  PendingOwner,
  PermissionSet,
  ResultSortDirection,
  User,
  UserContextApiResponse,
  UsersGridResult,
} from 'types/user';

import apiFunctions from '../..';

const UserUrl = '/api/v1/User/';

export const UserUrls = {
  GetPendingOwners: UserUrl + 'GetPendingOwners',
  ApproveOwner: UserUrl + 'ApproveOwner',
  GetUsersPermissions: UserUrl + 'GetUsersPermissions',
  SetCustomerAssignmentPermissions: UserUrl + 'SetCustomerAssignmentPermissions',
  GetAllPermissions: UserUrl + 'GetAllPermissions',
  InviteStaffMember: UserUrl + 'InviteStaffMember',
  GetUser: UserUrl + 'GetUser',
  GetImpersonateUser: UserUrl + 'GetImpersonateUser',
  GetAssignedUsers: UserUrl + 'GetAssignedUsers',
  GetFilteredAssignments: UserUrl + 'GetFilteredAssignments',
  GetUserManagementData: UserUrl + 'GetUserManagementData',
  GetOverrideTemplates: UserUrl + 'GetOverrideTemplates',
  SearchUsers: UserUrl + 'SearchUsers',
  SetActiveIdentifier: UserUrl + 'SetActiveIdentifier',
  UpdateCustomerAssignmentRoles: UserUrl + 'UpdateCustomerAssignmentRoles',
  UpdateAssignmentStatus: UserUrl + 'UpdateAssignmentStatus',
  SetUserPermissionOverrides: UserUrl + 'SetUserPermissionOverrides',
  VerifyOwnershipForCustomer: UserUrl + 'VerifyOwnershipForCustomer',
  getCustomerAddress: UserUrl + 'GetCustomerAddress',
  getCustomerAccountSettings: UserUrl + 'GetCustomerAccountSettings',
  getCustomerApplicationStatus: UserUrl + 'GetCustomerApplicationStatus',
};

async function GetPendingOwners(): Promise<PendingOwner[]> {
  return await apiFunctions.get(UserUrls.GetPendingOwners);
}

async function ApproveOwner(email: string) {
  return await apiFunctions.post(UserUrls.ApproveOwner, { email });
}

async function GetUsersPermissions(email: string): Promise<PermissionSet> {
  return await apiFunctions.post(UserUrls.GetUsersPermissions, { email });
}

async function GetAllPermissions(): Promise<AllPermissionsResult> {
  return await apiFunctions.get(UserUrls.GetAllPermissions);
}

async function InviteStaffMember(
  email: string,
  firstName: string,
  lastName: string,
  targetPermissionSet: string,
  customPermissions: Array<string>,
) {
  return await apiFunctions.post(UserUrls.InviteStaffMember, {
    targetAccountEmail: email,
    firstName,
    lastName,
    targetPermissionSet: targetPermissionSet,
    customPermissions: customPermissions,
  });
}

async function SetCustomerAssignmentPermissions(email: string, permissionSet: string, customPermissions: string[]) {
  return await apiFunctions.post(UserUrls.SetCustomerAssignmentPermissions, {
    targetAccountEmail: email,
    targetPermissionSet: permissionSet,
    customPermissions,
  });
}

async function GetUser(invitationCode?: string): Promise<UserContextApiResponse> {
  return await apiFunctions.post(
    UserUrls.GetUser,
    invitationCode
      ? {
          oneTimeInvitationCode: invitationCode,
        }
      : null,
  );
}

async function GetImpersonateUser(targetEmail: string, targetCustomerId: CustomerId): Promise<UserContextApiResponse> {
  return await apiFunctions.post(UserUrls.GetImpersonateUser, {
    targetEmail,
    targetCustomerId: {
      idType: targetCustomerId.idType,
      idValue: targetCustomerId.idValue,
    },
  });
}

async function SearchUsers(query: string, skip: number, take: number) {
  return await apiFunctions.post(UserUrls.SearchUsers, {
    query,
    skip,
    take,
  });
}

async function GetOverrideTemplates(): Promise<User[]> {
  return await apiFunctions.get(UserUrls.GetOverrideTemplates);
}

async function GetFilteredAssignments(filterText: string, page: number, pageSize: number) {
  return await apiFunctions.post(UserUrls.GetFilteredAssignments, {
    filterText,
    page,
    pageSize,
  });
}

export type GetUserManagementParam = {
  filterText: string;
  showInactive: boolean;
  sortBy: string;
  sortDirection: ResultSortDirection;
  page: number;
  pageSize: number;
};

async function GetUserManagementData(param: GetUserManagementParam): Promise<UsersGridResult['userManagementData']> {
  return await apiFunctions.post(UserUrls.GetUserManagementData, param);
}

async function SetActiveIdentifier(idValue: string, idType: string): Promise<boolean> {
  return await apiFunctions.post(UserUrls.SetActiveIdentifier, { customerId: { idType, idValue } });
}

async function UpdateAssignmentStatus(targetEmail: string, isActive: boolean) {
  return await apiFunctions.post(UserUrls.UpdateAssignmentStatus, { targetAccountEmail: targetEmail, isActive });
}

async function SetUserPermissionOverrides(override?: UserContextApiResponse): Promise<boolean> {
  return await apiFunctions.post(UserUrls.SetUserPermissionOverrides, override);
}

export type VerifyOwnershipMutation = {
  customerId: CustomerId;
  ssn: string;
  bankAccountNumber: string;
  fedTaxId: string;
};

async function VerifyOwnershipForCustomer(param: VerifyOwnershipMutation) {
  return await apiFunctions.post(UserUrls.VerifyOwnershipForCustomer, param);
}

async function GetCustomerAddress(): Promise<MDMCustomerAddress> {
  return await apiFunctions.get(UserUrls.getCustomerAddress);
}

async function GetCustomerAccountSettings(): Promise<CustomerAccountSettings> {
  return await apiFunctions.get(UserUrls.getCustomerAccountSettings);
}

async function GetCustomerApplicationStatus(): Promise<Array<ApplicationStatus>> {
  return await apiFunctions.get(UserUrls.getCustomerApplicationStatus);
}

const userService = {
  GetPendingOwners,
  ApproveOwner,
  GetUsersPermissions,
  SetCustomerAssignmentPermissions,
  GetAllPermissions,
  InviteStaffMember,
  GetUser,
  GetFilteredAssignments,
  GetImpersonateUser,
  GetOverrideTemplates,
  GetUserManagementData,
  SearchUsers,
  SetActiveIdentifier,
  UpdateAssignmentStatus,
  SetUserPermissionOverrides,
  VerifyOwnershipForCustomer,
  GetCustomerAddress,
  GetCustomerAccountSettings,
  GetCustomerApplicationStatus,
};

export default userService;
